import { H2, Image, Section } from '@ahmdigital/ui';
import { isEmpty } from 'lodash/fp';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
// @ts-expect-error - Automatic, Please fix when editing this file
import getAllRewards from '@ahmdigital/offers/lib/model/offer/get-all-rewards';
import React from 'react';

import { GROWTHBOOK_HOMEPAGE_OFFER_BANNER } from '../../../../ahm-constants/ui';
import filterOffersByProductType from '../../../../utils/filter-offers-by-product-type';
import getContentForOffer from '../../../../components/offers/tile/utils/get-content-for-offer';
import OfferHeading from '../../../../components/offers/tile/components/offer-heading';
import offerTagImage from '../../../../components/offers/images/offer-tag.svg';
import ProductPropType from '../../../../types/product';
import RewardsList from './components/rewards-list';
import type { Offer, Placeholder } from '../../../../components/offers/types';

type HeroOfferSectionProps = {
  activeOffers?: Offer[];
  lastViewedProduct: ProductPropType;
  placeholders?: Placeholder[];
  isPartnerReferral: boolean;
};

const HeroOfferSection = ({
  activeOffers,
  isPartnerReferral,
  lastViewedProduct,
  placeholders,
}: HeroOfferSectionProps) => {
  const isHomepageOfferBannerEnabled = useFeatureIsOn(GROWTHBOOK_HOMEPAGE_OFFER_BANNER);

  if (isEmpty(activeOffers) || !isHomepageOfferBannerEnabled || isPartnerReferral) {
    return null;
  }

  const lastViewedProductType = lastViewedProduct?.type;
  // @ts-expect-error - Automatic, Please fix when editing this file
  const offer = filterOffersByProductType(lastViewedProductType, activeOffers);

  const { ends, header, sublist } = getContentForOffer(offer);
  const rewards = getAllRewards(offer);

  return (
    <Section variant="paddingBottomSpacer9">
      <Image src={offerTagImage.src} alt="Offer" />
      <H2 variant="marginBottomSpacer12" looksLike="h3">
        {/* @ts-expect-error - Automatic, Please fix when editing this file */}
        <OfferHeading offer={offer} placeholders={placeholders} rewards={rewards} header={header} />
      </H2>
      <RewardsList sublist={sublist} ends={ends} />
    </Section>
  );
};

export default HeroOfferSection;
